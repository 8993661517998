import React, { useState } from "react";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Row,
  Col,
  Card,
  CardTitle,
  CardText,
} from "reactstrap";

import Skärmavbild from "./Skärmavbild.png";
import map from "./map.jpg";
import Samrådsunderlag from "./Samrådsunderlag.pdf";
import Fotomontage from "./Fotomontage.pdf";
import Ljudberäkning from "./Ljudberäkning.pdf";
import PresentationSamråd from "./Presentation samråd.pdf";
import Samrådsredogörels from "./Bilaga A1 - Samrådsredogörels.pdf";
import { FaFileDownload } from "react-icons/fa";


function FactAndInfor() {
  const [activeTab, setActiveTab] = useState("1");

  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  return (
    <div>
      <Nav tabs>
        <NavItem>
          <NavLink
            className={activeTab === "1" ? "active" : ""}
            onClick={() => toggleTab("1")}
            style={{ cursor: "pointer" }}
          >
            <h5>Bakgrund </h5>
          </NavLink>
        </NavItem>

        <NavItem>
          <NavLink
            className={activeTab === "2" ? "active" : ""}
            onClick={() => toggleTab("2")}
            style={{ cursor: "pointer" }}
          >
            <h5> Samråd </h5>
          </NavLink>
        </NavItem>

        <NavItem>
          <NavLink
            className={activeTab === "3" ? "active" : ""}
            onClick={() => toggleTab("3")}
            style={{ cursor: "pointer" }}
          >
            <h5>Tillståndsprocessen</h5>
          </NavLink>
        </NavItem>
      </Nav>

      <TabContent activeTab={activeTab} style={{ marginTop: "30px" }}>
        <TabPane tabId="1">
          <Row>
            <Col sm="6">
              <Card body>
                <CardTitle></CardTitle>
                <CardText>
                  Vindpark Östergötland AB utreder möjligheterna till att
                  etablera 14 vindkraftverk norr om Valdemarsviks tätort.
                  Ansökan överensstämmer med kommunens översiktsplan då området
                  utgör ett riksintresse för energiproduktion. Området består
                  till stor del av produktionsskog. Totalhöjden för
                  vindkraftverken som utreds är 270 m.
                </CardText>
              </Card>
            </Col>

            <Col sm="6">
              <Card body>
                <CardText>
                  <CardTitle></CardTitle>
                  <p>
                    <strong>Antal verk:</strong> 14 stycken
                  </p>
                  <p>
                    <strong>Totalhöjd: </strong> 270 meter
                  </p>
                  <p>
                    <strong> Kommun: </strong> Valdemarsvik
                  </p>
                  <p>
                 
                  <span>Läs mer om </span> 
                    <a href="https://www.naturvardsverket.se/om-miljoarbetet/forskning/vindval" target="_blank" rel="noopener noreferrer">
                      Vindval.se
                    </a>
                    
                  </p>
                  <br></br>
                  <p>
                    <a
                      href={Samrådsunderlag} // if it is local file
                      download
                    >
                      <FaFileDownload size={32} /> Samrådsunderlag
                    </a>
                  </p>

                  <p>
                    <a
                      href={Fotomontage} // if it is local file
                      download
                    >
                      <FaFileDownload size={32} /> Fotomontage
                    </a>
                  </p>
                  
                  <p>
                    <a
                      href={map} // if it is local file
                      download
                    >
                      <FaFileDownload size={32} /> Karta
                    </a>
                  </p>

                  <p>
                    <a
                      href={Ljudberäkning} // if it is local file
                      download
                    >
                      <FaFileDownload size={32} /> Ljudberäkning
                    </a>
                  </p>

                  <p>
                    <a
                      href={PresentationSamråd} // if it is local file
                      download
                    >
                      <FaFileDownload size={32} /> Presentation Samråd
                    </a>
                  </p>

                  <p>
                    <a
                      href={Samrådsredogörels} // if it is local file
                      download
                    >
                      <FaFileDownload size={32} /> Bilaga A1 -  Samrådsredogörels
                    </a>
                  </p>
                  
                  {/*
                 <p>
                    <a
                      href="https://srenergy.se/app/uploads/2021/05/Fotomontage-Vindpark-Mo%CC%88rtsjo%CC%88_lagupplost-210917-1.pdf"
                      target="_blank"
                      rel="Fotomontage - låg upplösning
"
                    >
                      <FaFileDownload size={32} />
                      Fotomontage - låg upplösning
                    </a>
                  </p>
                  */}
                </CardText>
              </Card>
            </Col>
          </Row>
        </TabPane>

        <TabPane tabId="2">
          <Row>
            <Col sm="12">
              <Card body>
                <CardTitle></CardTitle>
                <CardText>
                  <p>
                    Som en del i arbetet med miljöbedömningen genomför Vindpark
                    Östergötland AB nu avgränsningssamråd enligt miljöbalken (6
                    kap. 29 §). Syftet med samrådet är att informera om och
                    samla in synpunkter på den planerade vindparkens
                    lokalisering, omfattning och utformning samt vilka
                    miljöeffekter som behöver utredas i
                    miljökonsekvensbeskrivningen. Samrådet är det första steget
                    i tillståndsprocessen i arbetet med att ta fram en
                    tillståndsansökan.
                  </p>

                  <p>
                    Aktuellt samråd genomförs både skriftligen och genom ett
                    samrådsmöte i form av öppet hus där man har möjlighet att få
                    muntlig information samt ställa frågor och lämna skriftliga
                    synpunkter. Samtliga fastighetsägare över 18 år och
                    folkbokförda inom tre kilometer från vindkraftverken får
                    denna här inbjudan via brev.
                  </p>

                  <p>
                    Även identifierade verksamheter, föreningar och andra
                    intressenter som finns i anslutning till utredningsområdet
                    inbjuds. Övrig allmänhet bjuds in via tidningsannonser. Vi
                    ber er informera eventuella hyresgäster,
                    nyttjanderättshavare och arrendatorer om aktuellt samråd.
                  </p>
                </CardText>
              </Card>
            </Col>

            <Col sm="12">
              <Card body>
                {/*<CardTitle>Subtitle</CardTitle> */}
                <CardText>
                  <img
                    alt=""
                    src={Skärmavbild}
                    width="90%"
                    height="auto"
                    className="d-inline-block align-top"
                  />
                </CardText>
              </Card>
            </Col>
          </Row>
        </TabPane>

        <TabPane tabId="3">
          <Row>
            <Col sm="12">
              <Card body>
                <CardTitle>
                  Processen för ett vindkraftsprojekt består av flera viktiga
                  steg:
                </CardTitle>
                <CardText>
                  {/* <a
                    href="https://srenergy.se/app/uploads/2023/04/SR-Energy-QA-interactive-1.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Frågor och svar om vindkraft Läs mer om vindkraft
                  </a> */}
                  <p>
                    <strong>1. Utredning (1-3 år): </strong>
                    Inleds med noggranna platsundersökningar. Inventeringar och
                    analyser genomförs för att utvärdera potentiella
                    projektområden, med hänsyn till olika faktorer som
                    vindförhållanden, anslutning till elnätet, närhet till
                    boende, djur, växter, kulturella värden, infrastruktur och
                    andra intressen. Relevanta myndigheter kontaktas. Om inga
                    stora hinder påträffas kan beslut fattas om att gå vidare
                    till samrådet.
                  </p>

                  <p>
                    <strong>2. Samråd (0,5-1 år): </strong>
                    Tidigt i tillståndsprocessen bjuds berörda intressenter in
                    att delta i samråd enligt miljöbalken. Under samrådet ges
                    alla parter möjlighet att ställa frågor, ge feedback och
                    lämna information. Materialet som samlas in under samråden
                    sammanställs i den samrådsredogörelse som krävs för ansökan.
                    Ytterligare inventeringar kan behövas för att säkerställa en
                    högkvalitativ miljökonsekvensbeskrivning (MKB) i nästa steg.
                  </p>

                  <p>
                    <strong>3. Ansökan och MKB (2-3 år): </strong>
                    En ansökan om miljötillstånd för vindkraftsprojektet lämnas
                    in till miljöprövningsdelegationen vid länsstyrelsen för
                    granskning. Ansökan inkluderar också en MKB som beskriver
                    möjliga påverkningar av vindkraftsparken på människors hälsa
                    och miljön. Kommunen måste godkänna markens lämplighet för
                    vindkraftsprojektet för att miljötillståndet ska kunna
                    beviljas. Länsstyrelsen kräver ofta ytterligare information
                    innan ansökan anses vara komplett. När ansökan anses vara
                    komplett offentliggörs den.
                  </p>

                  <p>
                    <strong>4. Granskning (2-5 år): </strong>
                    Miljöprövningsdelegationen vid länsstyrelsen fattar beslut
                    om tillstånd baserat på ansökan och MKB. Beslutet kan
                    överklagas till nästa instans, mark- och miljödomstolen.
                    Domstolens beslut kan överklagas till högsta rättsliga
                    instansen, mark- och miljööverdomstolen, som då beslutar om
                    ärendet kan granskas eller inte. Det godkända
                    miljötillståndet innehåller en rad
                  </p>

                  <p>
                    <strong>5. Villkor: </strong>
                    Såsom ljudnivåer, skuggning och åtgärder för att skydda
                    natur- och kulturmiljön.
                  </p>

                  <p>
                    <strong>6. Detaljprojektering (1 år): </strong>
                    Om projektet beviljas tillstånd genomförs detaljprojektering
                    för att fastställa infrastruktur och byggmetodik, vilket
                    utgör grund för kostnadsuppskattningar och upphandling.
                  </p>

                  <p>
                    <strong>7. Upphandling (1 år): </strong>
                    Upphandling genomförs med hänsyn till affärsmässiga
                    principer och hållbarhetsaspekter. Turbiner, vägar,
                    kranplaner, kablenätverk och anslutningar upphandlas genom
                    olika huvudkontrakt.
                  </p>

                  <p>
                    <strong>8. Investeringsbeslut: </strong>
                    När alla tillstånd är på plats och upphandlingsprocessen är
                    avslutad, har styrelsen möjlighet att fatta ett
                    investeringsbeslut baserat på kostnadsuppskattningar och
                    marknadsanalyser. Därefter påbörjas byggandet.
                  </p>

                  <p>
                    <strong>9. Byggande (1-3 år): </strong>
                    Byggandet av en vindkraftspark följer dessa steg:
                    markrensning, förberedelse av området, väg- och kablarbeten,
                    elnätsanslutning och grundläggning. Bygget av en
                    vindkraftspark sker ofta i etapper. Slutligen installeras
                    vindturbinerna, genomgår noggranna tester och tas i drift.
                  </p>

                  <p>
                    <strong>10. Drift: </strong>
                    Vindturbiner börjar generera el så snart de tas i drift.
                    Löpande underhåll och service av vindkraftsparken utförs på
                    plats. Parkerna övervakas fjärran dygnet runt. Turbinerna
                    producerar förnybar el i upp till 30 år, och tillfälliga
                    områden som användes under byggandet återställs.
                  </p>

                  <p>
                    <strong>11. Eftervård och Avslut: </strong>
                    När turbinerna når slutet av sin livslängd monteras de ned.
                    Nya turbiner kan installeras, men då krävs ett nytt
                    tillstånd. Alternativt återställs marken, och material
                    återvinns eller hanteras enligt statliga föreskrifter. En
                    hållbar avveckling finansieras och godkänns redan innan
                    vindkraftsparken byggs.
                  </p>
                </CardText>
              </Card>
            </Col>

            {/*<Col sm="6">
              <Card body>
                <CardTitle>
                  Film från när vi byggde Vindpark Kronoberget:
                </CardTitle>
                <CardText>
                  <iframe
                    width="90%"
                    height="350px"
                    src="https://www.youtube.com/embed/7w6Qj0iDVHE"
                    title="All About Wind Energy"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowfullscreen
                  ></iframe>
                </CardText>
              </Card>
            </Col> */}
          </Row>
        </TabPane>
      </TabContent>
    </div>
  );
}

export default FactAndInfor;
