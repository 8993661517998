import React, { useState } from "react";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Row,
  Col,
  Card,
  CardTitle,
  CardText,
  Button,
} from "reactstrap";

import map from "./map.jpg";

function News() {
  const [activeTab, setActiveTab] = useState("1");

  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  return (
    <div>
      <Nav tabs>
        <NavItem>
          <NavLink
            className={activeTab === "1" ? "active" : ""}
            onClick={() => toggleTab("1")}
            style={{ cursor: "pointer" }}
          >
            <p>Januari 2025</p>
            <h5>Samtal om vindkraft</h5>
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={activeTab === "2" ? "active" : ""}
            onClick={() => toggleTab("2")}
            style={{ cursor: "pointer" }}
          >
            <p>14 Nov - 2023</p>
            <h5>Uppkommande Samråd </h5>
          </NavLink>
        </NavItem>

        <NavItem>
          <NavLink
            className={activeTab === "3" ? "active" : ""}
            onClick={() => toggleTab("3")}
            style={{ cursor: "pointer" }}
          >
            <p>-</p>
            <h5>Projektområde</h5>
          </NavLink>
        </NavItem>
        {/*
        <NavItem>
          <NavLink
            className={activeTab === "3" ? "active" : ""}
            onClick={() => toggleTab("3")}
          >
            <p>07/11 - 2023</p>
            <h5>News </h5>
          </NavLink>
        </NavItem>
        */}
      </Nav>

      <TabContent activeTab={activeTab} style={{ marginTop: "30px" }}>
        <TabPane tabId="1">
          <Row>
            <Col sm="12">
              <p>
              Med anledning av vår planerade vindpark i Valdemarsvik vill vi på detta sätt bjuda in till samtal över en kopp kaffe med er som önskar veta mer om våra planer.
              </p>

              <p>
              Under samtalet ges tillfälle att beröra frågor av allmän karaktär såväl som frågor gällande parkens utformning samt eventuella påverkan för enskilda fastigheter och bostäder, närregionen och samhället i stort.
              </p>

              <br></br>
              <p>
              Vi kommer finnas i Funkishuset i Valdemarsvik{" "}
                <strong>
                kl 14-19 fredag 10 januari, torsdag 16 januari och fredag 24 januari.
                </strong>.
              </p>
              <p>
              Önskar du en direkt kontakt med vindkraftbolaget nås vi bäst via{" "}
                <strong>jacob@blas.se</strong>.
              </p>
              <br></br>
              <p>Vi ser fram emot att ses!</p>

              <strong>Vindpark Östergötland AB</strong>
            </Col>
          </Row>
        </TabPane>

        <TabPane tabId="2">
          <Row>
            <Col sm="12">
              <p>
                Vindpark Östergötland AB utreder möjligheten att etablera en
                vindpark vid i Valdemarsviks kommun och bjuder härmed in till
                samråd enligt 6 kap. Miljöbalken.
              </p>
              <p>Välkomna till samrådet.</p>
              <br></br>
              <p>
                Anmäl er gärna till <strong>jacob@blas.se</strong> <br></br>
                Kaffe och enklare förtäring kommer finnas, vänligen informera
                oss om eventuella allergier.
              </p>

              <br></br>
              <p>
                <strong>Digital nerladdning av samrådsunderlag</strong>
              </p>
              <p>
                Samrådsunderlag och information om utställningen finns på
                bolagets hemsida<br></br>
                <a
                  href="http://vindparkostergotland.se/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  www.vindparkostergotland.se
                </a>
                <br></br>
                Efter avslutat samrådsmöte/öppet hus finns en digital version av
                det som presenterades under utställningen.
              </p>

              <br></br>

              <p>
                <strong>Papperskopia</strong>
              </p>
              <p>
                För önskemål om samrådsmaterial i papperskopia kontakta Jacob
                Falkman på <strong>jacob@blas.se </strong>
              </p>

              <br></br>
              <p>
                <strong> Inlämning av yttranden</strong>
              </p>
              <p>
                Vi ber er skicka ev Samrådsyttrande till: wsp, Per-Anders Alm,
                attn: Vindpark Östergötland, Södra Grytsgatan 7 – 602 33
                <br></br>
                Norrköping eller e-post: <strong>per-anders.alm@wsp.com</strong>
                <br></br>
                Yttrande skall vara oss tillhanda senast den 11 december 2023
              </p>
            </Col>
          </Row>
        </TabPane>

        <TabPane tabId="3">
          <Row>
            <Col sm="12">
              <Card body>
                <CardTitle></CardTitle>
                <CardText className="text-center">
                  <img src={map} width="90%" height="auto" />
                </CardText>
              </Card>
            </Col>
          </Row>
        </TabPane>

        <TabPane tabId="3">
          <Row>
            <Col sm="12">
              <p>
                Efter genomförda inventeringar och samråd är vår bedömning att
                antalet vindkraftverk i området kommer bli färre än de som
                presenterades i samrådslayouten. Under vintern 2023 kommer vi
                att genomföra ytterligare inventeringar i området. Därefter
                planerar vi att bjuda in till ett kompletterande samråd under
                våren 2023 med anledning av de förändrade förutsättningarna. Då
                kommer vi att presentera en ny utformning av Vindpark Mörtsjö.
                Inbjudan till samrådet kommer skickas via post under våren.
              </p>
            </Col>
          </Row>
        </TabPane>
      </TabContent>
    </div>
  );
}

export default News;
